var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('v-spacer'),_vm._v(_vm._s(_vm.multipleDelete ? "Excluir Notícias selecionadas?" : "Excluir Notícia?")),_c('v-spacer')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Sim")]),_c('v-spacer')],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"sort-by":_vm.sortBy,"options":_vm.options,"sort-desc":"","search":_vm.$store.state.search,"loading":_vm.loading,"loading-text":"Carregando...","show-select":"","single-select":false,"server-items-length":_vm.totalDesserts,"footer-props":{'items-per-page-options': [10,15,20], firstIcon: 'mdi-arrow-collapse-left', lastIcon: 'mdi-arrow-collapse-right','items-per-page-text':'Notícias por página'},"item-key":"_id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" Nenhum registro encontrado ")]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Notícias")]),_c('v-spacer'),_c('actions',{attrs:{"showActiveAll":_vm.selected.length,"showSave":_vm.itemsToSave.length},on:{"update":_vm.update,"switch":function (data) { return (_vm.status = data); },"delete":_vm.deleteItems}}),_c('tooltip')],1)]},proxy:true},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status ? "Ativo" : "Inativo")+" ")]}},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('img',{attrs:{"src":("" + (item.logo || '/img/logo1.jpg')),"alt":"Logo"}})])]}},{key:"item.text",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"},attrs:{"title":item.text},domProps:{"innerHTML":_vm._s(item.text)}})]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"},attrs:{"title":item.link}},[_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.link))])])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('start-date',{attrs:{"item":item}})]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('end-date',{attrs:{"item":item}})]}},{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"color":item.color,"size":"36","title":item.color}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total || 0)+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }