<template>
  <div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          ><v-spacer></v-spacer
          >{{
            multipleDelete
              ? "Excluir Notícias selecionadas?"
              : "Excluir Notícia?"
          }}<v-spacer></v-spacer
        ></v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >Sim</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :sort-by="sortBy"
      :options.sync="options"
      sort-desc
      class="elevation-1"
      :search="$store.state.search"
      :loading="loading"
      loading-text="Carregando..."
      show-select
      :single-select="false"
      :server-items-length="totalDesserts"
      :footer-props="{'items-per-page-options': [10,15,20], firstIcon: 'mdi-arrow-collapse-left', lastIcon: 'mdi-arrow-collapse-right','items-per-page-text':'Notícias por página'}"
      v-model="selected"
      item-key="_id"
    >
      <template v-slot:no-data> Nenhum registro encontrado </template>
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Notícias</v-toolbar-title>
          <v-spacer></v-spacer>

          <actions
            :showActiveAll="selected.length"
            :showSave="itemsToSave.length"
            @update="update"
            @switch="(data) => (status = data)"
            @delete="deleteItems"
          ></actions>
          <tooltip></tooltip>
        </v-toolbar>
      </template>

      <template v-slot:item.status="{ item }">
        {{ item.status ? "Ativo" : "Inativo" }}
      </template>

      <template v-slot:item.logo="{ item }">
        <v-avatar  @click="editItem(item)">
          <img :src="`${item.logo || '/img/logo1.jpg'}`" alt="Logo" />
        </v-avatar>
      </template>

      <template v-slot:item.text="{ item }">
        <span
          v-html="item.text"
          :title="item.text"
          class="d-inline-block text-truncate"
          style="max-width: 150px"
        >
        </span>
      </template>

      <template v-slot:item.link="{ item }">
        <span
          style="max-width: 150px"
          :title="item.link"
          class="d-inline-block text-truncate"
          ><a :href="item.link" target="_blank">{{ item.link }}</a></span
        >
      </template>

      <template v-slot:item.startDate="{ item }">
        <start-date :item="item"></start-date>
      </template>

      <template v-slot:item.endDate="{ item }">
        <end-date :item="item"></end-date>
      </template>

      <template v-slot:item.color="{ item }">
        <v-avatar :color="item.color" size="36" :title="item.color"></v-avatar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template v-slot:item.total="{ item }">
        {{ item.total || 0 }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import actions from "./actions.vue";
import { deleteNews, getNews, updateNews } from "../../services/news";
import tooltip from "./tooltip";
import startDate from "./startDate.vue";
import endDate from "./endDate.vue";
export default {
  components: { tooltip, actions, startDate, endDate },
  data: () => ({
    timeoutToSearch: 0,
    options: {sortBy: ['startDate'], sortDesc: [true], filter: {}},
    totalDesserts:0,
    multipleDelete: false,
    sortBy: ["startDate"],
    selected: [],
    itemsToSave: [],
    status: true,
    clicked: "",
    loading: true,
    search: "",
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Logo", value: "logo" },
      { text: "Status", value: "status" },
      { text: "Total em Playlists", value: "total" },
      { text: "Usuário", value: "user.name" },
      {
        text: "Texto da Notícia",
        align: "start",
        sortable: true,
        value: "text",
        filterable: true,
      },
      // { text: "Imagem Principal", value: "img" },
      { text: "Copyright da Imagem", value: "copyright" },
      { text: "Cor", value: "color" },
      { text: "Link da Notícia", value: "link" },
      { text: "Data de início", value: "startDate" },
      { text: "Data de finalização", value: "endDate" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    originalDesserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    showSave() {
      return !!Object.keys(this.itemsToSave).length;
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
        handler () {
          clearTimeout(this.timeoutToSearch)
          this.timeoutToSearch = setTimeout(()=>{
            this.initialize()
          }, 300)
        },
        deep: true,
      },
      "$store.state.search"(v){
        this.options.filter = {$or: [{text: { $regex: v, $options: 'i' }}, {"user.name": {$regex: v, $options: 'i'}}]}        
      }
  },

  created() {
    //this.initialize();
  },

  methods: {
    async update() {
      this.$store.state.loading = true;
      this.itemsToSave = this.itemsToSave.map((item) => {
        const dataSelect = this.selected.find((it) => it._id == item._id);
        return { ...item, status: dataSelect ? this.status : item.status };
      });

      this.selected = this.selected.map((item) => {
        item.status = this.status;
        const itemToSave = this.itemsToSave.find((it) => it._id == item._id);
        if (itemToSave) {
          return {
            ...item,
            startDate: itemToSave.startDate,
            endDate: itemToSave.endDate,
          };
        }

        return item;
      });
      const concat = [...this.itemsToSave, ...this.selected];
      const unics = {};
      concat.forEach((item) => (unics[item._id] = item));
      const data = Object.keys(unics).map((key) => unics[key]);

      await updateNews(data)
        .then((msg) => {
          this.$store.commit("success", msg);
          window.location.reload();
        })
        .catch((err) => {
          this.$store.commit(
            "failed",
            "Falha ao tentar salvar dados!\r\n" + err.stack
          );
        });
      this.$store.state.loading = false;
    },

    setEndDate(date, item) {
      const newItem = Object.assign({}, item);
      item.endDateUpdated =
        date + "T" + (item.endDate || new Date().toISOString()).split("T")[1];
      newItem.endDate = item.endDateUpdated;
      if (
        this.desserts.find((data) => data._id == item._id).endDate !=
        newItem.endDate
      ) {
        this.itemsToSave = this.itemsToSave.filter(
          (data) => data._id != item._id
        );
        this.itemsToSave.push(newItem);
      } else {
        delete item.endDateUpdated;
        this.itemsToSave = this.itemsToSave.filter(
          (data) => data._id != item._id
        );
      }
    },
    setStartDate(date, item) {
      const newItem = Object.assign({}, item);
      item.startDateUpdated =
        date + "T" + (item.startDate || new Date().toISOString()).split("T")[1];
      newItem.startDate = item.startDateUpdated;
      if (
        this.desserts.find((data) => data._id == item._id).startDate !=
        newItem.startDate
      ) {
        this.itemsToSave = this.itemsToSave.filter(
          (data) => data._id != item._id
        );
        this.itemsToSave.push(newItem);
      } else {
        delete item.startDateUpdated;
        this.itemsToSave = this.itemsToSave.filter(
          (data) => data._id != item._id
        );
      }
    },
    async initialize() {
      this.loading = true;
      await getNews(this.options)
        .then((data) => {
          this.desserts = data.items;
          this.totalDesserts = data.total
        })
        .catch(() => {});
      this.loading = false;
    },

    editItem(item) {
      this.$store.state.news.edited = true;
      Object.keys(item).forEach((key) => {
        this.$store.state.news[key] = item[key];
      });
      this.$store.commit('templates/setItems', item.templates)
      
      this.$store.state.news.userSelected = (item.user || {})._id;
      this.$router.push("add/" + item._id);
      // this.editedIndex = this.desserts.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      // this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItems() {
      this.dialogDelete = true;
      this.multipleDelete = true;
    },

    async deleteItemConfirm() {
      this.$store.state.loading = true;
      if (this.multipleDelete) {
        for (const item of this.selected) {
          await deleteNews(item._id);
        }
      } else {
        await deleteNews(this.desserts[this.editedIndex]._id);
        this.desserts.splice(this.editedIndex, 1);
      }
      this.$store.commit("success", "Notícia excluída com sucesso!");
      //this.$store.state.snackbar = true
      this.closeDelete();
      this.$store.state.loading = false;
      document.location.reload();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
  beforeDestroy() {
    this.$store.state.searched = false;
  },
  mounted() {
    this.$store.state.searched = true;
  },
};
</script>